import React from "react";
import CookieHOC from "./cookieHOC";

const Cookie = () => {
  return (
    <>
      <CookieHOC />
    </>
  );
};

export default Cookie;
