import React from "react";

import SignupForm from "./signupHOC";

const Signup = () => {
  return (
    <>
      <SignupForm />
    </>
  );
};

export default Signup;
