import React from 'react';
import PropTypes from "prop-types";

export default function IconTwitter({fill = 'white'}) {
    return (
        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 3.19864C23.1075 3.59014 22.1565 3.84964 21.165 3.97564C22.185 3.36664 22.9635 2.40964 23.3295 1.25614C22.3785 1.82314 21.3285 2.22364 20.2095 2.44714C19.3065 1.48564 18.0195 0.890137 16.6155 0.890137C13.8915 0.890137 11.6985 3.10114 11.6985 5.81164C11.6985 6.20164 11.7315 6.57664 11.8125 6.93364C7.722 6.73414 4.1025 4.77364 1.671 1.78714C1.2465 2.52364 0.9975 3.36664 0.9975 4.27414C0.9975 5.97814 1.875 7.48864 3.183 8.36314C2.3925 8.34814 1.617 8.11864 0.96 7.75714C0.96 7.77214 0.96 7.79164 0.96 7.81114C0.96 10.2021 2.6655 12.1881 4.902 12.6456C4.5015 12.7551 4.065 12.8076 3.612 12.8076C3.297 12.8076 2.979 12.7896 2.6805 12.7236C3.318 14.6721 5.127 16.1046 7.278 16.1511C5.604 17.4606 3.4785 18.2496 1.1775 18.2496C0.774 18.2496 0.387 18.2316 0 18.1821C2.1795 19.5876 4.7625 20.3901 7.548 20.3901C16.602 20.3901 21.552 12.8901 21.552 6.38914C21.552 6.17164 21.5445 5.96164 21.534 5.75314C22.5105 5.06014 23.331 4.19464 24 3.19864Z" fill={fill}/>
        </svg>
    )
}

IconTwitter.propTypes = {
    fill: PropTypes.string
};