import React from "react";
import ResetPasswordHOC from "./ResetPasswordHOC";

const ResetPassword = () => {
  return (
    <>
      <ResetPasswordHOC />
    </>
  );
};

export default ResetPassword;
