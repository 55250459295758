import React from "react";
import NewPasswordHOC from "./NewPasswordHOC";

const newPassword = () => {
  return (
    <>
      <NewPasswordHOC />
    </>
  );
};

export default newPassword;
