import React from "react";
import TermsOfUseHOC from "./termsOfUseHOC";

const termsOfUse = () => {
  return (
    <>
      <TermsOfUseHOC />
    </>
  );
};

export default termsOfUse;
