import React from "react";
import PrivacyHOC from "./privacyHOC";

const Privacy = () => {
  return (
    <>
      <PrivacyHOC />
    </>
  );
};

export default Privacy;
