import React from 'react';
import PropTypes from "prop-types";

export default function IconYoutube({fill = 'white'}) {
    return (
        <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.0645 1.44991C22.1982 0.420077 20.5987 0 17.5441 0H6.45569C3.33112 0 1.70454 0.447175 0.841471 1.54359C0 2.61259 0 4.18769 0 6.36767V10.5227C0 14.746 0.9984 16.8903 6.45569 16.8903H17.5442C20.1931 16.8903 21.661 16.5196 22.6106 15.6108C23.5845 14.6788 24 13.1571 24 10.5227V6.36767C24 4.0687 23.9349 2.48431 23.0645 1.44991ZM15.4081 9.01881L10.3729 11.6504C10.2603 11.7092 10.1372 11.7384 10.0143 11.7384C9.87507 11.7384 9.73618 11.7009 9.61316 11.6265C9.38152 11.4861 9.24008 11.235 9.24008 10.9642V5.71796C9.24008 5.44761 9.38113 5.19677 9.61231 5.05634C9.84356 4.9159 10.1312 4.9063 10.3711 5.03094L15.4063 7.64555C15.6625 7.77856 15.8234 8.04302 15.8237 8.33156C15.8241 8.62034 15.6639 8.88519 15.4081 9.01881Z" fill={fill}/>
        </svg>
    )
}

IconYoutube.propTypes = {
    fill: PropTypes.string
};