import React from "react";

import AddRosterHOC from "./AddRosterHOC";

const AddRoster = () => {
  return (
    <>
      <AddRosterHOC />
    </>
  );
};

export default AddRoster;
