import React from "react";

import LoginForm from "./loginHOC";

const Login = () => {
  return (
    <>
      <LoginForm />
    </>
  );
};

export default Login;
