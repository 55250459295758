import React from "react";
import ConfirmPasswordHOC from "./ConfirmPasswordHOC";

const ConfirmPassword = () => {
  return (
    <>
      <ConfirmPasswordHOC />
    </>
  );
};

export default ConfirmPassword;
